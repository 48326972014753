import React from 'react';
import { makeStyles, createStyles, Theme, Container } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
        hero: {
            flexGrow: 1,
            padding: '6rem 0',
            width: "100%",
        },
        hero_primary: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        hero_secondary: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        hero_grey: {
            backgroundColor: theme.palette.background.grey,
        },
    }),
);

type Props = {
    children: any;
    color?: "primary" | "secondary" | "grey" | "none";
}

const Section = (props: Props) => {
    const {children, color} = props;
    const classes = useStyles();

    let colorClass = "";
    switch (color) {
        case "primary": colorClass = classes.hero_primary; break;
        case "secondary": colorClass = classes.hero_secondary; break;
        case "grey": colorClass = classes.hero_grey; break;
    }

    return <section className={clsx(classes.hero, colorClass)}>
        <Container>
            {children}
        </Container>
    </ section>
}

export default Section;