import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LogoSvg from "../images/logo.svg";
import { Link } from "gatsby-theme-material-ui";
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.grey,
        zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
        margin: "-20px 3rem -40px 0",
        borderRadius: "290486px",
        background: theme.palette.background.grey,
        '& img': {
            marginBottom: 0,
        }
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 0,
      marginRight: theme.spacing(4),
    },
  }),
);

const Header = () => {
    const classes = useStyles();


    return     <div className={classes.root}>
                <Toolbar>
                    <Link to="/">
                        <div className={classes.logo}>
                            <img style={{width: "100px"}} src={LogoSvg} alt="hERP Logo" />
                        </div>
                    </Link>
                    <Typography className={classes.title}>
                        <Link to="/">Home</Link>
                    </Typography>
                    <Typography className={classes.title}>
                        <Link to="/docs">Documentation</Link>
                    </Typography>
                </Toolbar>
        </div>
}

export default Header;